
    ::v-deep .el-breadcrumb {
        .el-breadcrumb__inner {
            color: #000;
        }
    }
    .quiz-container {
        width: 100%;
        height: calc(100% - 40px);
        padding: 20px 0;
        .quiz-content {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            .quiz-list {
                display: flex;
                flex-direction: column;
                height: 1%;
                flex: 1;
                background: #fff;
                margin-top: 20px;
                .course-list {
                    padding: 20px;
                }
            }
        }
    }
